let TEST_MODE;
let HOST;
let confs;

const SOC = "test";

TEST_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

function getHost() {
  if (false) {
    switch (SOC) {
      case "efc":
        HOST = "blancreme.ilsduc.fr";
        break;
      case "ei":
        HOST = "clients.etiquettes-impression.fr";
        break;
      case "so":
        HOST = "clients.somafi27.com";
        break;
      case "test":
        HOST = "test.ilsduc.fr";
        break;
      case "ping":
        HOST = "ping.ilsduc.fr";
        break;
      case "pc":
      default:
        HOST = "picourt.wop-apps.ovh";
        break;
    }
  } else {
    HOST = window.location.hostname;
  }
  return HOST;
}
/*
 ** Configurations
 */
function getConfigurations(host) {
  switch (host) {
    case "client.picourt.wop-apps.ovh":
      confs = {
        URL_API: "https://client.picourt.wop-apps.ovh/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/picourt-cabis",
        FAVICON: "/src/favicons/picourt.favicon.png",
        TITLE: "Test- Site Client",
        META_NAME_CONTENT: "#97b89d",
        MANIFEST: "/src/manifests/picourt.manifest.json",
        PRIMARY_BG: "#808080",
        SITE_MARCHAND: "http://google.com",
      };
      break;
    case "picourt.wop-apps.ovh":
      confs = {
        URL_API: "https://picourt.wop-apps.ovh/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/picourt-cabis",
        FAVICON: "/src/favicons/picourt.favicon.png",
        TITLE: "Test - Site Client",
        META_NAME_CONTENT: "#97b89d",
        MANIFEST: "/src/manifests/picourt.manifest.json",
        PRIMARY_BG: "#808080",
        SITE_MARCHAND: "http://google.com",
      };
      break;
    case "client.blanccreme.wop-apps.ovh":
      confs = {
        URL_API: "https://blanccreme.wop-apps.ovh/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/blancreme",
        FAVICON: "/src/favicons/blancreme.favicon.png",
        TITLE: "TEST - Site Client",
        META_NAME_CONTENT: "#97b89d",
        MANIFEST: "/src/manifests/blancreme.manifest.json",
        PRIMARY_BG: "#808080",
        SITE_MARCHAND: "http://google.com",
      };
      break;
    case "clients.picourt-cabis.fr":
      confs = {
        URL_API: "https://clients.picourt-cabis.fr/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/picourt-cabis",
        FAVICON: "/src/favicons/picourt.favicon.png",
        TITLE: "Picourt-Cabis | Documents",
        META_NAME_CONTENT: "#997975",
        MANIFEST: "/src/manifests/picourt.manifest.json",
        PRIMARY_BG: "#997975",
        SITE_MARCHAND: "https://www.picourt-cabis.com/fr/",
      };
      break;
    case "clients.blancreme.com":
      confs = {
        URL_API: "https://clients.blancreme.com/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/blancreme",
        FAVICON: "/src/favicons/blancreme.favicon.png",
        TITLE: "Blancrème Paris | Documents",
        META_NAME_CONTENT: "#fca2a2",
        MANIFEST: "/src/manifests/blancreme.manifest.json",
        // PRIMARY_BG: "#A2D8B2",
        // PRIMARY_BG: "#92c9a2",
        PRIMARY_BG: "#fca2a2",
        SITE_MARCHAND: "https://www.blancreme.com/",
      };
      break;
    case "clients.etiquettes-impression.fr":
      confs = {
        URL_API: "https://clients.etiquettes-impression.fr/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/etiquettes-impression",
        PRIMARY_BG: "#009ee2",
        FAVICON: "/src/favicons/etiquettes-impression.favicon.png",
        TITLE: "Etiquettes Impression | Documents",
        META_NAME_CONTENT: "#009ee2",
        MANIFEST: "/src/manifests/etiquettes-impression.manifest.json",
        SITE_MARCHAND: "https://www.etiquettes-impression.fr/",
      };
      break;
    case "clients.somafi27.com":
      confs = {
        URL_API: "https://clients.somafi27.com/api",
        URL_GED: "https://nextcloud.picourt-cabis.fr/somafi",
        PRIMARY_BG: "#FF5E19",
        FAVICON: "/src/favicons/somafi.favicon.png",
        TITLE: "Somafi | Documents",
        META_NAME_CONTENT: "#FF5E19",
        MANIFEST: "/src/manifests/somafi.manifest.json",
        SITE_MARCHAND: "https://www.somafi.fr/",
      };
      break;
      case "localhost":
        confs = {
          URL_API: "http://localhost:3010/api",
          URL_GED: "https://nextcloud.picourt-cabis.fr/picourt-cabis",
          FAVICON: "/src/favicons/picourt.favicon.png",
          TITLE: " Test- Site Client",
          META_NAME_CONTENT: "#97b89d",
          MANIFEST: "/src/manifests/picourt.manifest.json",
          PRIMARY_BG: "#000080",
          SITE_MARCHAND: "http://google.com",
        };
        break;
  }
}

/*
 ** Setting attributes for index.html
 */
export function applyConfiguration() {
  /*
   ** Favicon
   */
  document.getElementById("FAVICON").setAttribute("href", confs.FAVICON);
  /*
   ** Manifest
   */
  document.getElementById("MANIFEST").setAttribute("href", confs.MANIFEST);
  /*
   ** Manifest
   */
  document.getElementById("TITLE").innerHTML = confs.TITLE;
  /*
   ** Meta Themecolor
   */
  document
    .getElementById("META_NAME_CONTENT")
    .setAttribute("content", confs.META_NAME_CONTENT);
}

getConfigurations(getHost());

applyConfiguration();

export { confs };

export const rootPath = "/picourt-test";
